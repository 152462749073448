<template>
  <v-card class="elevation-0">
    <v-row class="mr-3 mb-3">
      <v-col>
        <v-autocomplete v-model="selectedAdNetworks" :items="adNetworks" multiple label="Ad networks" clearable prepend-icon="stream">
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field v-model="accountNameSearch" label="Account name" clearable prepend-icon="account_balance">
        </v-text-field>
      </v-col>
      <v-col>
        <v-autocomplete v-model="selectedTypes" :items="types" multiple label="Types" clearable prepend-icon="build">
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-btn class="mt-2" color="primary" v-on:click="show">Show</v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      show-expand
      single-expand
      class="elevation-0"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.entry_at`]="{ item }">
        {{ datatables_formatTimestamp(item.entry_at) }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <span>{{ item.external_message }}</span>
        </td>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'
import nkwMixin from '@/mixins/negativeKeywords'

export default {
  name: 'NKWSyncErrorsTable',

  data () {
    return {
      headers: [
        { text: 'Network', value: 'ad_network' },
        { text: 'Account ID', value: 'account_id' },
        { text: 'Account name', value: 'account_name' },
        { text: 'Type', value: 'sync_type' },
        { text: 'Message', value: 'message' },
        { text: 'Entry at', value: 'entry_at' },
        { text: '', value: 'data-table-expand' },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      types: ['Update lists', 'Link campaigns'],
      selectedAdNetworks: [],
      accountNameSearch: '',
      selectedTypes: [],
      lsOptions: 'nkwSyncErrors_options',
      lsFilters: 'nkwSyncErrors_filters',
      dataEndpoint: '/a/nkw/sync-errors',
    }
  },

  mixins: [dataTablesMixin, nkwMixin],

  computed: {
    adNetworks () {
      return this.$store.state.core.adNetworks
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    fetchData: function () {
      let myURL = this.dataEndpoint + '?xfields='
      this.loading = true

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['data-table-expand'], ['id', 'external_message'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // filter ad networks
      if (this.selectedAdNetworks.length > 0) {
        myURL += '&ad_network=' + this.selectedAdNetworks.join('|')
      }

      // filter account name search
      if (this.accountNameSearch) {
        myURL += '&account_name=~' + this.accountNameSearch + '~'
      }

      // filter types
      if (this.selectedTypes.length > 0) {
        myURL += '&sync_type=' + this.selectedTypes.join('|')
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    show: function () {
     let filterObj = {
        'selectedAdNetworks': this.selectedAdNetworks,
        'accountNameSearch': this.accountNameSearch,
        'selectedTypes': this.selectedTypes,
      }

      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['selectedAdNetworks']) { this.selectedAdNetworks = filters['selectedAdNetworks'] }
      if (filters['accountNameSearch']) { this.accountNameSearch = filters['accountNameSearch'] }
      if (filters['selectedTypes']) { this.selectedTypes = filters['selectedTypes'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>
</style>
