var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-row',{staticClass:"mr-3 mb-3"},[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.adNetworks,"multiple":"","label":"Ad networks","clearable":"","prepend-icon":"stream"},model:{value:(_vm.selectedAdNetworks),callback:function ($$v) {_vm.selectedAdNetworks=$$v},expression:"selectedAdNetworks"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Account name","clearable":"","prepend-icon":"account_balance"},model:{value:(_vm.accountNameSearch),callback:function ($$v) {_vm.accountNameSearch=$$v},expression:"accountNameSearch"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.types,"multiple":"","label":"Types","clearable":"","prepend-icon":"build"},model:{value:(_vm.selectedTypes),callback:function ($$v) {_vm.selectedTypes=$$v},expression:"selectedTypes"}})],1),_c('v-col',[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":_vm.show}},[_vm._v("Show")])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","loading":_vm.loading,"options":_vm.options,"footer-props":{
      'items-per-page-options': _vm.datatables_rowsPerPageItems,
    },"server-items-length":_vm.totalItems,"show-expand":"","single-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" Loading items.. ")]},proxy:true},{key:"item.entry_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datatables_formatTimestamp(item.entry_at))+" ")]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('span',[_vm._v(_vm._s(item.external_message))])])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(_vm.datatables_footerPageText(_vm.totalItemsIsEstimate, _vm.estimatedItems, props))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }